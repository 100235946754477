<template>
    <form action @submit.prevent="login">
        <div class="d-flex flex-column justify-content-between text-dark-gray login-size" style="gap: 43px;">
            <div class="image-size mx-auto">
                <img src="/img/login/icon-user.png" alt="" class="h-100 w-100 rounded-circle bg-white shadow-inner obj-none">
            </div>
            <div>
                <p class="text-center mb-2 f-16">Ingrese su usuario</p>
                <input type="text" class="shadow-inner outline-none w-100 border-0 br-8 px-3 input__height" v-model="model.username" >
                <vee-error class="mt-2" :text="login_errors[0]"/>
            </div>
            <div>
                <p class="text-center mb-2 f-16">Ingrese su contraseña</p>
                <input type="password" class="shadow-inner outline-none w-100 border-0 br-8 px-3 input__height" v-model="model.password" >
                <vee-error class="mt-2" :text="login_errors[1] || login_alert"/>
            </div>
            <div class="d-flex justify-content-center">
                <button class="btn btn-general px-5 f-600" type="submit">Ingresar</button>
            </div>
            <div class="d-flex flex-column text-center w-fit-content mx-auto" >
                <el-button type="text" class="text-dark-gray underline f-18" @click="navigateTo({ name: 'recover', params: {tipo: 'clave'} })">
                    ¿Olvidó su contraseña?
                </el-button>
                <el-button type="text" class="text-dark-gray underline f-18 ml-0" @click="navigateTo({ name: 'recover', params: {tipo: 'usuario'} })">
                    ¿Olvidó su usuario?
                </el-button>
            </div>
        </div>
    </form>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    middleware: 'guest',
    layout:'login',
    data(){
        return {
            model: {
                username: '',
                password: '',
            },
            login_errors: [],
            login_alert: '',
        }
    },
    computed:({
        ...mapGetters({
            user: 'auth/user',
            token: 'auth/token'
        }),
    }),
    watch:{
        "model.username"(val){
            if(val != '') this.login_errors[0] = '';
        },
        "model.password"(val){
            if(val != ''){
                this.login_errors[1] = '';
                this.login_alert = '';
            }
        },
    },
    created(){
        
    },
    methods:{
        isProveedores(){
            const subdomain = window.location.hostname.split('.').shift()
            return (['proveedores', 'proveedores-prueba']).includes(subdomain)
        },
        async login() {
            try {
                this.login_errors = this.validateForm(this.model);

                if (this.login_errors.some(el => el != '')) return;

                await this.$store.dispatch('auth/LOGIN', this.model);
                if (!this.token) return
                await this.$store.dispatch('auth/FETCH_USER');

                if (!this.token) return this.login_alert = 'El usuario o contraseña son incorrectos, intentelo de nuevo';

                if (this.isProveedores()) this.navigateTo({ name: 'proveedores.usuarios' })
                else this.navigateTo({ name: 'home' });
            } catch (error) {
                console.log("error", error)
            }
        },
        validateForm(model){
            const errors = [];
            Object.entries(model).some(([key, value], index) => {
                const map = {username: 'nombre de usuario', password: 'contraseña'}
                errors[index] = value == '' ? `El campo ${map[key]} no puede estar vacio` : ''
            });
            return errors;
        },
    }
}
</script>

<style scoped lang="scss">
.login-size{
    width: 350px;
}

</style>